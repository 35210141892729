// Allow developers to customize the Asset search text placeholder so that uses view
// a specific placeholder based on the type of asset they are searching.

const DEFAULT_TEXT = "Start typing to see a list of suggestions";

export function getSearchPlaceholder(type: string | null | undefined): string {
  if (!type) return DEFAULT_TEXT;
  switch (type.toLowerCase()) {
    case "fleet":
      return "Search for accounts, namespaces, configuration types, asset types...";
    case "api tokens":
      return "Search for API token names, descriptions...";
    case "available_integrations":
      return "Search available integrations...";
    case "detected_assets":
      return "Search for names, technologies, risk...";
    case "service account":
      return "Search for key IDs, services...";
    case "compliance_controls":
      return "Search controls";
    case "compliance_policies":
      return "Search for names, technologies, checks...";
    case "compliance_checks":
      return "Filter by checks, policies, impact...";
    case "compliance_assets":
      return "Filter by asset name, platform, completion...";
    case "asset_data_queries":
      return "Filter queries...";
    case "compliance_data_query_assets":
      return "Filter by asset name...";
    case "inventory_asset_checks":
      return "Filter checks...";
    case "organization_spaces":
      return "Filter spaces...";
    case "vm_software":
      return "Search for software, impact, risk factors...";
    case "vm_vulnerabilities":
      return "Search for vulnerability, impact, risk factors...";
    case "vm_advisories":
      return "Search for advisory, impact, risk factors...";
    case "space_vulns_vulnerability":
      return "Search vulnerabilities...";
    case "space_vulns_advisory":
      return "Search advisories...";
    case "space_vulns_asset":
      return "Type part or all of the asset name filter the list...";
    case "space_vulns_check":
      return "Search checks...";
    case "space_vulns_software":
      return "Type part of all of the software name to filter the list...";
    case "space_vulns_allsecurityfindings":
      return "Search CVEs and checks...";
    case "security_policies":
      return "Filter policies...";
    case "workspaces":
      return "Filter workspaces...";
    default:
      return DEFAULT_TEXT;
  }
}
