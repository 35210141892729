import { useEffect } from "react";
import {
  createSearchParams,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { ErrorBoundary } from "~/components/error-boundary";
import { LoadingPage } from "~/components/loading";
import { useViewer } from "~/providers/viewer";
import { TokenProvider } from "~/providers/token";
import {
  IntegrationsRoutes,
  InventoryRoutes,
  PageLayout,
  Redirect,
  SettingsRoutes,
  VulnsRoutes,
} from "~/routes";
import { IamActions } from "~/lib/iam";
import {
  LoadViewerDocument,
  useGetIntegrationsSummaryQuery,
} from "~/operations";
import { CicdRoutes } from "~/routes/space/cicd-routes";
import { AppNavDrawerItem, useAppNavDrawer } from "~/providers/app-nav-drawer";
import {
  AttachIcon,
  NavDrawerCicdIcon,
  NavDrawerFleetIcon,
  NavDrawerIntegrationsIcon,
  NavDrawerOverviewIcon,
  NavDrawerReportingIcon,
  NavDrawerRiskActionsIcon,
  NavDrawerSecurityIcon,
  NavDrawerSettingsIcon,
  NavDrawerVulnerabilitiesIcon,
  NavDrawerWorkspacesIcon,
} from "~/components/icons";
import { FindingsRoutes } from "~/routes/space/findings-routes";
import { ComplianceRoutes } from "~/routes/space/compliance-routes";
import { isFeatureEnabled } from "~/login/features";
import { SoftwareDetails } from "~/pages/inventory/components/SoftwareDetails";
import { ComplianceNavIcon } from "./compliance/temporary/ComplianceNavIcon";
import { SpaceDashboard } from "./dashboard-space";
import { FullTextSearchResults } from "./Search";
import { CasesRoutes } from "~/routes/space/cases-routes";
import { CaseDetailsPage } from "~/components/cases/components/CaseDetailsPage";
import { WorkspaceRoutes } from "~/routes/space/workspace-routes";
import { isSpaceOrWorkspaceScope, useScope } from "~/hooks/useScope";
import { ReportingRoutes } from "~/routes/space/reporting-routes";

export type SpacePagesProps = {};

export function SpacePages({}: SpacePagesProps) {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { viewerSettings, updateViewerSettings } = useViewer();
  const { setTree } = useAppNavDrawer();

  const spaceId = searchParams.get("spaceId");
  const workspaceMrn = searchParams.get("workspaceId");
  const isWorkspace = !!workspaceMrn;

  const { activeScope: scope, spaceScope } = useScope();
  const space = spaceScope;
  const spaceMrn = spaceScope?.mrn;

  const { data: integrationSummaryData, loading: integrationSummaryLoading } =
    useGetIntegrationsSummaryQuery({
      variables: { input: { spaceMrn: spaceMrn || "" } },
      skip: !spaceMrn,
    });

  const integrationsSummary = integrationSummaryData?.integrationsSummary;
  const integrationCount = integrationsSummary?.total ?? 0;
  //check if any integrations exist
  const hasIntegrations = integrationCount > 0;
  // loop through the integrations and check if any at all are failing, return true or false
  const hasFailingIntegrations = Boolean(
    integrationsSummary?.integrationSummaries?.some(
      ({ failedCount, warningCount }) => failedCount > 0 || warningCount > 0,
    ),
  );

  const hasUpdateSpacePermission = spaceScope?.iamActions.includes(
    IamActions.CAPTAIN_UPDATESPACE,
  );
  const hasCreateIntegrationPermission = spaceScope?.iamActions.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );
  const hasListCasesPermission = spaceScope?.iamActions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
  );

  // TODO: add "last_workspace_id"?
  useEffect(() => {
    // If user's current spaceId is different from last spaceId
    if (space && space.id !== viewerSettings?.last_space_id) {
      // Update user's last spaceId
      updateViewerSettings({
        variables: { key: "last_space_id", value: space.id },
        refetchQueries: [LoadViewerDocument],
      });
    }
  }, [space]);

  useEffect(() => {
    const integrationsTree: AppNavDrawerItem["tree"] = [
      {
        text: "All Integrations",
        link: {
          to: `/space/integrations?${scope?.params}`,
        },
        hasError: hasFailingIntegrations,
      },
      hasCreateIntegrationPermission
        ? {
            text: "Add New Integration",
            link: {
              to: `/space/integrations/add?${scope?.params}`,
            },
          }
        : undefined,
    ].flatMap((x) => x ?? []);

    const findingsTree: AppNavDrawerItem["tree"] = [
      {
        text: "All",
        link: {
          to: `/space/findings/all?${scope?.params}`,
        },
      },
      {
        text: "Vulnerabilities",
        link: {
          to: `/space/findings/cves?${scope?.params}`,
        },
      },
      {
        text: "Advisories",
        link: {
          to: `/space/findings/advisories?${scope?.params}`,
        },
      },
      {
        text: "Checks",
        link: {
          to: `/space/findings/checks?${scope?.params}`,
        },
      },
      {
        text: "Policies",
        link: {
          to: `/space/findings/policies?${scope?.params}`,
        },
      },
    ].flatMap((navItem) => navItem ?? []);

    const inventoryTree: AppNavDrawerItem["tree"] = [
      {
        text: "Assets",
        link: { to: `/space/inventory?${scope?.params}` },
      },
      {
        text: "Software",
        link: {
          to: `/space/inventory/software?${scope?.params}`,
        },
      },
      {
        text: "Exposed assets",
        link: {
          to: `/space/inventory/exposed-assets?${scope?.params}`,
        },
      },
      {
        text: "Query packs",
        link: { to: `/space/inventory/query-packs?${scope?.params}` },
      },
    ];

    setTree(
      [
        {
          text: "Dashboard",
          icon: <NavDrawerOverviewIcon />,
          link: {
            to: `/space/overview?${scope?.params}`,
          },
        },
        {
          text: "Workspaces",
          icon: <NavDrawerWorkspacesIcon />,
          link: {
            to: `/space/workspaces?${spaceScope?.params}`,
          },
        },
        {
          text: "Inventory",
          icon: <NavDrawerFleetIcon />,
          link: {
            to: `/space/inventory?${scope?.params}`,
          },
          tree: inventoryTree,
        },
        {
          text: "Findings",
          icon: <NavDrawerVulnerabilitiesIcon />,
          link: {
            to: `/space/findings?${scope?.params}`,
          },
          tree: findingsTree,
        },
        !isWorkspace
          ? {
              text: "Compliance",
              icon: <ComplianceNavIcon />,
              link: {
                to: `/space/compliance?${scope?.params}`,
              },
            }
          : null,
        !isWorkspace
          ? {
              text: "CI/CD",
              icon: <NavDrawerCicdIcon />,
              link: {
                to: `/space/cicd?${scope?.params}`,
              },
            }
          : null,
        !isWorkspace
          ? {
              text: "Reporting",
              icon: <NavDrawerReportingIcon />,
              link: {
                to: `/space/reporting?${scope?.params}`,
              },
            }
          : null,
        hasListCasesPermission
          ? {
              text: "Ticketing",
              icon: <AttachIcon />,
              link: {
                to: `/space/cases?${scope?.params}`,
              },
            }
          : null,
        !isWorkspace
          ? {
              text: "Integrations",
              icon: <NavDrawerIntegrationsIcon />,
              beforeDivider: true,
              link: {
                to:
                  hasIntegrations || !hasCreateIntegrationPermission
                    ? `/space/integrations?${scope?.params}`
                    : `/space/integrations/add?${scope?.params}`,
              },
              tree: hasIntegrations ? integrationsTree : undefined,
              loading: integrationSummaryLoading,
              selected: location.pathname.startsWith("/space/integrations"),
            }
          : null,
        hasUpdateSpacePermission
          ? {
              text: "Settings",
              icon: <NavDrawerSettingsIcon />,
              link: {
                to: `/space/settings?${scope?.params}`,
              },
            }
          : null,
      ].flatMap((navItem) => navItem ?? []),
    );
    return () => setTree([]);
  }, [
    hasUpdateSpacePermission,
    hasCreateIntegrationPermission,
    hasListCasesPermission,
    integrationSummaryLoading,
    integrationCount,
    scope?.params,
  ]);

  // if we don't have a space id param, try to redirect to the last visited space
  if (!spaceId) {
    const spaceId = viewerSettings?.last_space_id;
    if (spaceId) {
      let newParams: { [key: string]: string | string[] } = {};
      searchParams.forEach((_key: string, value: string) => {
        newParams[value] = searchParams.getAll(value);
      });

      newParams["spaceId"] = spaceId;
      return (
        <Navigate
          replace
          to={window.location.pathname + "?" + createSearchParams(newParams)}
        />
      );
    }
    return <Navigate to="/dashboard" replace />;
  }

  if (!spaceScope || !scope || !isSpaceOrWorkspaceScope(scope) || !space) {
    return <LoadingPage />;
  }

  const availablePermissions = spaceScope.iamActions;

  return (
    <TokenProvider space={space} availablePermissions={availablePermissions}>
      <ErrorBoundary key="space">
        <Routes>
          {/* Integrations Routes */}
          <Route
            path="integrations/*"
            element={
              <IntegrationsRoutes {...{ space, availablePermissions }} />
            }
          />

          {/* Fleet Routes */}
          {/* Deprecated - Redirect to "/inventory" */}
          <Route
            path="fleet/*"
            element={<Redirect from="space/fleet" to="space/inventory" />}
          />

          <Route element={<PageLayout />}>
            <Route
              index
              element={<SpaceDashboard space={space} scope={scope} />}
            />

            <Route
              path="workspaces/*"
              element={<WorkspaceRoutes {...{ space, availablePermissions }} />}
            />

            {/* Overview Route */}
            <Route
              path="overview"
              element={<SpaceDashboard space={space} scope={scope} />}
            />

            {/* Settings Routes */}
            <Route
              path="settings/*"
              element={
                <SettingsRoutes {...{ space, availablePermissions, scope }} />
              }
            />

            {/* CI/CD Projects Routes */}
            <Route
              path="cicd/*"
              element={<CicdRoutes {...{ space, availablePermissions }} />}
            />

            {/* Reporting Routes */}
            <Route
              path="reporting/*"
              element={<ReportingRoutes {...{ space, availablePermissions }} />}
            />

            {/* Compliance Routes */}
            <Route
              path="compliance/*"
              element={
                <ComplianceRoutes {...{ space, availablePermissions }} />
              }
            />

            <Route path="software">
              <Route
                path=":name/*"
                element={<SoftwareDetails scope={scope} />}
              />
            </Route>

            {hasListCasesPermission && (
              <Route
                path="cases/*"
                element={
                  <CasesRoutes {...{ space, scope, availablePermissions }} />
                }
              />
            )}
            <Route
              path="case"
              element={
                <CaseDetailsPage
                  availablePermissions={availablePermissions}
                  space={space}
                  scope={scope}
                />
              }
            />
          </Route>
          <Route element={<PageLayout maxWidth={false} />}>
            <Route
              path="search"
              element={<FullTextSearchResults scope={scope} />}
            />
          </Route>
          {/* Inventory Routes */}
          <Route
            path="inventory/*"
            element={
              <InventoryRoutes
                {...{ scope, spaceScope, space, availablePermissions }}
              />
            }
          />
          {/* Vulnerabilities Routes */}
          <Route
            path="vulns/*"
            element={
              <VulnsRoutes {...{ space, scope, availablePermissions }} />
            }
          />
          {/* New Vulnerabilities Routes */}
          <Route
            path="findings/*"
            element={
              <FindingsRoutes
                availablePermissions={availablePermissions}
                spaceScope={spaceScope}
                scope={scope}
              />
            }
          />
        </Routes>
      </ErrorBoundary>
    </TokenProvider>
  );
}
